<template>
  <v-container class="ma-0 pa-0">
    <v-card class="mt-6" width="70%" min-height="50vh" max-height="50vh" :loading="loading">
      <v-card-title class="textDark--text title font-weight-regular" primary-title>Available Trainings</v-card-title>
      <v-divider></v-divider>
      <v-row v-if="loading || !loading && trainings.length === 0" justify="center" align="center" class="mt-4">
        <div v-if="loading" class="subtitle-1 secondaryText--text">Loading trainings...</div>
        <div class="subtitle-1 secondaryText--text" v-else>No trainings to preview</div>
      </v-row>

      <v-card-text>
        <template v-if="trainings">
          <v-card @click="trainingClicked(training)" width="70%" hover class="mb-2" v-for="training in trainings" :key="training._id">
            <v-card-title class="subtitle-1 primary--text">{{ training.name }}</v-card-title>
            <v-card-text class="mt-n4">{{ training.description }}</v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "user-training",
  data() {
    return {
      trainingId: "",
      trainingIds: [],
      accountId: "",
      trainingData: null,
      loading: false
    };
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("userElevation", ["trainings"])
  },
  created() {
  
    this.refresh();
  },
  methods: {

    ...mapActions("auth",{
      refreshCurrentUser: "refreshCurrentUser"
    }),

    ...mapActions("training", {
      getTrainings: "getTrainings",
      fetchAvailableTrainings: "fetchAvailableTrainings"
    }),

    ...mapActions("userElevation", {
      getTrainings: "getTrainings",
      setSelectedTraining: "setSelectedTraining"
    }),

    async refresh() {
      try {
        this.loading = true;
        if (!this.currentUser.trainings || this.currentUser.trainings.length === 0){
          await refreshCurrentUser();
        }
        await this.getTrainings(this.currentUser.trainings);
      } finally {
        this.loading = false;
      }
    },

    trainingClicked(item){
      this.setSelectedTraining(item);

      this.$router.push({
        name: 'user-courses',
        params: {
          trainingId: item._id
        }
      });
    }
  }
};
</script>
